import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "index" */ '@/views/Index.vue')
  },
  {
    path: '/about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
  },
  {
    path: '/privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-of-service',
    component: () => import(/* webpackChunkName: "terms-of-service" */ '@/views/TermsOfService.vue')
  },
  {
    path: '/pricing',
    component: () => import(/* webpackChunkName: "pricing" */ '@/views/Pricing.vue')
  },
  {
    path: '/affiliate-program',
    component: () => import(/* webpackChunkName: "affiliate-program" */ '@/views/AffiliateProgram.vue')
  },
  {
    path: '/thai',
    component: () => import(/* webpackChunkName: "thai" */ '@/views/thai/Index.vue')
  },
  {
    path: '/thai/course/:courseslug',
    component: () => import(/* webpackChunkName: "thai-course-slug" */ '@/views/thai/course/Slug.vue')
  },
  {
    path: '/admin',
    component: () => import(/* webpackChunkName: "admin" */ '@/views/admin/Index.vue')
  },
  {
    path: '/profile',
    component: () => import(/* webpackChunkName: "profile" */ '@/views/Profile.vue')
  },
]

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
