<template>
  <header>
    <router-link to="/" class="index">
      <img src="@/assets/logo.png" />
      <span class="title">PocketLang</span>
      <span class="tld">.com</span>
    </router-link>
    <div class="links">
      <router-link to="/profile" v-if="authUser && authUser.uid" class="account">Profile</router-link>
      <span v-else-if="!isFetchingUser" @click="login" class="account spanlink">Login</span>
      <router-link to="/pricing" class="pricing">Pricing</router-link>
    </div>
  </header>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  methods: {
    ...mapActions(['showLoginOverlay']),
    login() {
      this.showLoginOverlay(true)
      // this.$notify.warning("Authentication coming soon")
    },
  },
  computed: {
    ...mapGetters(['authUser', 'isFetchingUser'])
  },
}
</script>

<style lang="scss" scoped>
header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5rem;
  background-color: var(--nav-background-color);
  border-bottom: 1px solid var(--nav-border-bottom-color);
  padding: 0.25rem 1rem 0 1rem;
  .index {
    text-decoration: none;
    img {
      max-height: 2rem;
    }
    .title,
    .tld {
      color: var(--header-text-color);
      font-weight: 700;
      text-decoration: none;
    }
    .title {
      font-size: 2.5rem;
    }
    .tld {
      font-size: 1.25rem;
    }
  }
  .links {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.5rem;
    align-content: center;
    justify-content: end;
  }
  @media (max-width: 30rem) {
    .links {
      grid-template-columns: auto;
      .pricing {
        display: none;
      }
    }
  }
  @media (max-width: 25rem) {
    .links {
      .account {
        display: none;
      }
    }
  }
  @media (max-width: 22.5rem) {
    .tld {
      display: none;
    }
  }
  @media (max-width: 20rem) {
    .index img {
      display: none;
    }
  }
}
</style>