import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueClipboard from 'vue-clipboard2'
import VueNotify from 'vuejs-notify'
import { auth } from './services/firebase'

Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.use(VueNotify)

// const auth = getAuth();
auth.onAuthStateChanged(async user => {
  if (!user) {
    store.dispatch('logoutAuthUser')
    return
  } else if (user && user.emailVerified === false) {
    console.log('send verification email')
  }
  store.dispatch('setAuthUser', user)
  store.dispatch('showLoginOverlay', false)
  // if (user) {
  //   // User is signed in, see docs for a list of available properties
  //   // https://firebase.google.com/docs/reference/js/firebase.User
  //   const uid = user.uid;
  //   console.log(uid)
  //   // ...
  // } else {
  //   // User is signed out
  //   // ...
  // }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
