import { auth } from "@/services/firebase";
// import { auth, db } from '@/services/firebase';
// import { stripe } from '@/services/stripe';

export default {
  state: {
    authUser: null,
    isFetchingUser: true,
    showLoginOverlay: false,
  },
  actions: {
    async setAuthUser({ commit }, payload) {
      if (payload.uid) {
        let token = await payload.getIdTokenResult()
        const user = {
          uid: payload.uid,
          email: payload.email,
          displayName: payload.displayName,
          isAdmin: token.claims?.isAdmin,
        }
        commit("SET_AUTH_USER", user)
      }
      commit("SET_IS_FETCHING_USER", false);
    },
    // async getAuthUser({ commit }) {
    //   let user = null;
    //   commit("SET_IS_FETCHING_USER", true);
    //   await fetch("https://pocketlang.com/api/user", {
    //     method: "GET",
    //     headers: {
    //       Accept: "application/json",
    //       Authorization: await auth.currentUser.getIdToken(),
    //     },
    //   })
    //     .then((r) => r.json())
    //     .then((data) => {
    //       console.log("data: " + data);
    //       if (data.user) {
    //         user = data.user;
    //       }
    //     })
    //     .catch((e) => console.error("err", e));
    //   commit("SET_AUTH_USER", user);
    //   commit("SET_IS_FETCHING_USER", false);
    //   commit("SET_SHOW_LOGIN_OVERLAY", false);
    // },
    async logoutAuthUser({ commit }) {
      commit("SET_AUTH_USER", null);
      commit("SET_IS_FETCHING_USER", false);
    },
    async showLoginOverlay({ commit }, payload) {
      commit("SET_SHOW_LOGIN_OVERLAY", payload);
    },
    // async startStripeCheckoutSubscription(_, payload) {
    //   const docRef = await db
    //     .collection('users')
    //     .doc(auth.currentUser.uid)
    //     .collection('checkout_sessions')
    //     .add({
    //       price: payload,
    //       success_url: window.location.origin,
    //       cancel_url: window.location.origin,
    //     });
    //   docRef.onSnapshot((snap) => {
    //     const { error, sessionId } = snap.data();
    //     if (error) {
    //       console.log(error)
    //       return error
    //     }
    //     if (sessionId) {
    //       stripe.redirectToCheckout({ sessionId });
    //     }
    //   });
    // }
  },
  mutations: {
    SET_AUTH_USER(state, data) {
      // console.log("set auth user.." + data.uid);
      state.authUser = data;
    },
    SET_IS_FETCHING_USER(state, value) {
      state.isFetchingUser = value;
    },
    SET_SHOW_LOGIN_OVERLAY(state, value) {
      state.showLoginOverlay = value;
    },
  },
  getters: {
    authUser(state) {
      return state.authUser;
    },
    isAdmin(state) {
      if (!state.authUser) {
        return true;
        // return false
      }
      return state.authUser.isAdmin;
    },
    isLoggedIn(state) {
      return state.authUser !== null && state.authUser.id !== "";
    },
    isFetchingUser(state) {
      return state.isFetchingUser;
    },
    showLoginOverlay(state) {
      return state.showLoginOverlay;
    },
    userIdToname: (state) => (id) => {
      return state.users.find((user) => user.id === id)?.name;
    },
    async userToken() {
      return await auth.currentUser.getIdToken();
    },
  },
};
