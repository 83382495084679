// import { apiHTTP } from "@/utils/request.js";
import { db } from "@/services/firebase.js";
import {
  addDoc,
  doc,
  updateDoc,
  getDocs,
  collection,
  query,
  where,
  limit,
  startAfter,
  orderBy,
} from "firebase/firestore/lite";

export const getPhrasesService = async ({
  categories = null,
  count = 10,
  lastUid = null,
}) => {
  try {
    let ref = collection(db, "phrases");
    let q = query(ref, orderBy("__name__", "asc"), limit(count));
    if (lastUid) {
      q = query(q, startAfter(lastUid));
    }
    if (categories) {
      q = query(q, where("categories", "array-contains-any", categories));
    }
    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((doc) => {
      let phrase = doc.data();
      phrase.id = doc.id;
      data.push(phrase);
    });
    return { data, hasError: false };
  } catch (error) {
    return { data: error, hasError: true };
  }
};

export const addPhraseService = async ({ payload = null }) => {
  try {
    const docRef = await addDoc(collection(db, "phrases"), payload);
    return { data: docRef, hasError: false };
  } catch (error) {
    return { data: error.response.data.message, hasError: true };
  }
};

export const updatePhraseService = async ({ uid = null, phrase = null }) => {
  try {
    const phraseRef = doc(db, "phrases", uid);
    await updateDoc(phraseRef, {
      english: phrase.english,
      thai: phrase.thai,
      categories: phrase.categories,
    });
    return { data: phrase, hasError: false };
  } catch (error) {
    return { data: error.response.data.message, hasError: true };
  }
};
