import {
  addPhraseService,
  updatePhraseService,
  getPhrasesService,
} from "@/api/phrases.api";

export default {
  state: {
    phrases: [],
    courseIds: [
      "accommodation",
      "directions",
      "education",
      "entertainment",
      "food",
      "immigration",
      "nationalities",
      "numbers",
      "office",
      "romance",
    ],
    demoPhrases: [
      {
        id: "1",
        english: {
          text: "Please drive slowly",
          tags: ["drive", "move", "go", "slow", "slowly"],
        },
        thai: {
          isGenderNeutral: false,
          text: "ขับช้าลงหน่อยครับ",
          transcript: "kàp cháa long nòi kráp",
          audio: "kfnejdu8.mp3",
          male: {
            text: "ขับช้าลงหน่อยครับ",
            transcript: "kàp cháa long nòi kráp",
            audio: "kfnejdu8.mp3",
          },
          female: {
            text: "ขับช้าลงหน่อยครับ",
            transcript: "kàp cháa long nòi kráp",
            audio: "kfnejdu8.mp3",
          },
        },
        categories: ["directions"],
      },
      {
        id: "2",
        english: {
          text: "Turn left after the bridge",
          tags: ["drive", "move", "go", "left", "bridge"],
        },
        thai: {
          isGenderNeutral: true,
          text: "เลี้ยวซ้ายเมื่อเจอสะพาน",
          transcript: "líieow sáai mêuua jer sà-paan",
          audio: "dkkhfd89.mp3",
        },
        categories: ["directions"],
      },
      {
        id: "3",
        english: {
          text: "How do I get to the bus stop?",
          tags: ["bus", "stop", "bus stop", "station", "bus station"],
        },
        thai: {
          isGenderNeutral: true,
          text: "ไปป้ายรถเมล์ยังไงครับ",
          transcript: "bpai bpâai rót may yang ngai kráp",
          audio: "iftyd67s.mp3",
        },
        categories: ["directions"],
      },
    ],
  },
  actions: {
    async addPhrase(_, payload) {
      try {
        const { data, hasError } = await addPhraseService({ payload });
        return { data, hasError };
      } catch (error) {
        console.log(error);
      }
    },
    async updatePhrase(_, { uid, phrase }) {
      console.log("update phrase store");
      try {
        const { data, hasError } = await updatePhraseService({ uid, phrase });
        return { data, hasError };
      } catch (error) {
        console.log(error);
      }
    },
    async getPhrases({ commit }, payload) {
      try {
        const { lastUid, count, categories } = payload;
        if (lastUid === null || lastUid === "") {
          commit("SET_PHRASES", { phrases: [], shouldAppend: false });
        }
        const { data, hasError } = await getPhrasesService({
          count,
          lastUid,
          categories,
        });
        if (!hasError) {
          const shouldAppend = lastUid !== null && lastUid !== "";
          commit("SET_PHRASES", { phrases: data, shouldAppend });
        }
        return { data, hasError, hasMore: data.length === count };
      } catch (error) {
        console.log("error:", error);
      }
    },
  },
  mutations: {
    SET_PHRASES(state, payload) {
      const { phrases, shouldAppend } = payload;
      if (shouldAppend) {
        state.phrases = [...state.phrases, ...phrases];
        return;
      }
      state.phrases = phrases;
    },
  },
  getters: {
    phrases(state) {
      return state.phrases;
    },
    demoPhrases(state) {
      return state.demoPhrases;
    },
    courseIds(state) {
      return state.courseIds;
    },
  },
};
