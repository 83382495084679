<template>
  <div class="push">
    <Header></Header>
    <main>
      <div
        id="firebaseui-auth-container"
        class="firebaseui"
        v-bind:class="{ show: showLoginOverlay }"
      ></div>
      <div v-if="showLoginOverlay" id="close-login-overlay">
        <a class="pointer" @click="closeLoginOverlay"> Cancel </a>
      </div>
      <template v-else>
        <router-view />
      </template>
    </main>
    <Footer></Footer>
  </div>
</template>


<script>
import firebase from "firebase/compat/app";
import { auth } from "@/services/firebase";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import { mapGetters } from "vuex";
export default {
  methods: {
    async startFirebaseAuthContainer() {
      var uiConfig = {
        signInSuccessUrl: "/pricing",
        signInOptions: [firebase.auth.EmailAuthProvider.PROVIDER_ID],
        tosUrl: "/terms-of-service",
        privacyPolicyUrl: function () {
          window.location.assign("/privacy-policy");
        },
      };
      if (firebaseui.auth.AuthUI.getInstance()) {
        const ui = firebaseui.auth.AuthUI.getInstance();
        ui.start("#firebaseui-auth-container", uiConfig);
      } else {
        var ui = new firebaseui.auth.AuthUI(auth);
        ui.start("#firebaseui-auth-container", uiConfig);
      }
    },
    closeLoginOverlay() {
      this.$store.dispatch("showLoginOverlay", false);
    },
  },
  computed: {
    ...mapGetters(["isLoggedIn", "isFetchingUser", "showLoginOverlay"]),
  },
  async created() {
    this.startFirebaseAuthContainer();
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
:root {
  --header-text-color: #094067;
  --normal-text-color: #5f6c7b;
  --link-text-color: #46bd87;
  --link-hover-underline-color: #46bd87;
  --nav-background-color: #fff;
  --nav-border-bottom-color: #eaecef;
  --phrase-background-color: #fff;
  --phrase-svg-color: #094067;
  --button-success-background-color: #40c463;
  --button-text-color: #fff;
}

html,
body {
  height: 100%;
  min-height: 100%;
}
body {
  margin: 0;
  font-family: "Krub", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--header-text-color);
  margin: 0.25rem 0;
}
p,
span {
  color: var(--normal-text-color);
}
p {
  margin: 0.25rem 0 0.5rem 0;
}
textarea {
  display: block;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 16px;
  color: #5f6c7b;
}
a {
  color: var(--link-text-color);
  font-weight: 600;
}
.spanlink {
  color: var(--link-text-color);
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
}
a:hover {
  text-decoration: none;
}

.push {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

#firebaseui-auth-container {
  margin-top: 2rem;
}
.firebaseui {
  position: absolute;
  left: -1000px;
  visibility: hidden;
  opacity: 0;
  height: 0;
  width: 0;
  overflow: hidden;
}
.show {
  position: initial;
  left: initial;
  visibility: initial;
  opacity: initial;
  height: initial;
  width: initial;
  overflow: initial;
}
#close-login-overlay {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
</style>
